/* eslint-disable */
// export const initLocation = {};
  // const layout = {
  //   name: 'cose-bilkent', // First layout (you can change this to any layout you prefer)
  //   randomize : true,
  //   nodeRepulsion: 29500,
  //   // animate : false,
  //   idealEdgeLength: 3000,
  //   nodeDimensionsIncludeLabels: true,
  //   'text-wrap': 'wrap',
  //   avoidOverlap: true, // Avoid node overlap
  //   nodeOverlap: 50000, // Increase spacing between nodes
  //   // animationDuration: 1000
  //   refresh: 300,
  //   fit : true,
  //   stop(event) {
  //     event.cy.nodes().forEach((ele) => {
  //       initLocation[ele.id()] = { x: ele.position().x, y: ele.position().y };
  //     });
  //   },

import Popover from "../container/PopoverTemplate";
  const getLayout = (showChart) => {
    return {
      name: 'cose-bilkent',
      idealEdgeLength: 450,
      animate : false,
      // refresh: 300,
      nodeDimensionsIncludeLabels: true,
      fit: true,
      // randomize: true,
      nodeRepulsion: 9500,
      // stop(event) {
      //   event.cy.nodes().forEach((ele) => {
      //     initLocation[ele.id()] = { x: ele.position().x, y: ele.position().y };
      //   });
      // },
      
    };
  }
  
  

export default getLayout;
