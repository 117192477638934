/* eslint-disable */ 
import { createSlice } from "@reduxjs/toolkit";
import { strategyLists } from "../presentation/TradesGrid";


const orderExecutionTimeGetter = (params) => {
    return params.value.replace('T', ' ').slice(0, 19);
};
   

const priceGetter = (params) => { 
    return Number(params.value).toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
}
const quantityGetter = (params) => { 
    return Number(params.value).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });
}

const TradeTypeCellRenderer = (props)=>{
    if(props.value === "BUY"){
        return <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{props.value }</span>
    }
    return <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{props.value}</span>
}

const initialState = {
    columnOptions : [
        {
            headerName: "  ",  
            width: 15, cellStyle: { textAlign: "left" },
            sort: null
        },
        {
            headerName: "Trading Symbol", field: 'tradingSymbol', 
            minWidth: 150, cellStyle: { textAlign: "left" },
            sort: null
            
        },
        {
            headerName: "Broker", field: 'broker', tooltipField: "broker", width: 130,
            cellStyle: { textAlign: "left" },
            sort: null
        },
        {
            headerName: "Strategy Name", field: "strategyLists", 
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            width:180,
            sort: null,
            valueGetter: (params) => {
                const strategyId = params?.data?.strategyId; 
                return strategyId && strategyLists ? strategyLists[strategyId] : ''
            }    
        },
        {
            headerName: "Exchange", field: 'exchange', width: 140,
            cellStyle: { textAlign: "left" },flex:0,
            sort: null
        },
        {
            headerName: "Type", field: 'transactionType',
            cellStyle: { textAlign: "left" },flex:0,width:100,
            sort: null,
            cellRenderer:TradeTypeCellRenderer,
        },
        {
            headerName: "Quantity", field: 'quantity',valueFormatter:quantityGetter,
            headerClass: "ag-right-aligned-header",
            cellStyle:{display: "flex",justifyContent: "flex-end"},
            width: 120,
            sort: null  
        },
        {
            headerName: "Price", field: 'price', tooltipField: "price",valueFormatter:priceGetter,
            headerClass: "ag-right-aligned-header",
            cellStyle:{display: "flex",justifyContent: "flex-end"}   ,
            width: 120,
            sort: null  
        },
        {
            headerName: "Order Execution Time", field: 'orderExecutionTime',valueFormatter:orderExecutionTimeGetter ,
            minWidth: 200,
            headerClass: "ag-right-aligned-header",
            cellStyle:{display: "flex",justifyContent: "flex-end"}   ,
            flex: 1,
            sort: null
           
        },
    ]
}

const TradesTableOptionSlice = createSlice({
    name:'tradesTableOption',
    initialState,
    reducers: {
        setTradestableOptionsData(state,action){
            state.columnOptions = action.payload;
        }
    }
})

export const {setTradestableOptionsData} = TradesTableOptionSlice.actions;
export default TradesTableOptionSlice.reducer