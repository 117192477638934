/*eslint-disable*/
import { useDispatch } from 'react-redux';
import './OptionChain.css'
import { setCallType, setShowOrderForm, setTradingSymbol } from './OptionChainSlice'
const ButtonRenderer = ({value, tradingSymbol}) => {
  const dispatch = useDispatch()
  return (
    <div>
        <span className='value-column'>{isNaN(value) ? value : Number(value).toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2})}</span>
        <div className="button-container">
            <button 
              className="buy-button" 
              onClick={
                () => {
                     dispatch(setShowOrderForm(true)); 
                     dispatch(setTradingSymbol(tradingSymbol))
                     dispatch(setCallType("BUY"))
                    }
                  }
            >B</button>
            <button 
              className="sell-button" 
              onClick={
                () => {
                  dispatch(setShowOrderForm(true));
                  dispatch(setTradingSymbol(tradingSymbol))
                  dispatch(setCallType("SELL"))
                  }}
            >S</button>
        </div>
    </div>
    
  );
};

export default ButtonRenderer;
