import React from 'react'
import fetchBetaValues from './getPortfoliobeta'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGlobalState } from 'components/globalVariable'
import { useDispatch } from 'react-redux'

export default function BetaContainer() {

    const betaValue = useSelector(state =>{
        return state.portfolioBeta.portfolioBeta
    })
    const [clientId] = useGlobalState("clientId")
    const [accountIds] = useGlobalState('accountIds')
    const dispatch = useDispatch();

    useEffect(()=>{
        fetchBetaValues(clientId,accountIds,dispatch)
    },[clientId,accountIds,dispatch])

    return (
<div className="flex items-center justify-center space-x-2 text-md border rounded-sm px-2 py-2 shadow-sm text-black-800">
<div className="flex items-center w-22 text-sm font-normal space-x-2">
    <span >Beta: </span> <span className='text-sm text-black font-semibold'>{betaValue?betaValue:"NA"}</span>
</div>

</div>
    )
}
