/*eslint-disable*/
import React, {useMemo} from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { useState, useEffect } from "react";
import { useGlobalState } from 'components/globalVariable';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from "reactstrap";
import classNames, { ShimmerTable } from 'react-shimmer-effects';
import { setPositionTableOptionsData } from '../container/PositionTableOptionSlice';
import TableRefresher from 'components/TableRefreshComponet/TableRefresher';
import PositionOrderForm from '../container/PositionOrderForm';
import OrderForm from 'components/OrderForm/Field/OrderForm';
import config  from "../../../config";

let strategyLists
const PositionsGrid = () => {
    
    const [count, setCount]             = useState(1);
    const [showSpinner, setShowSpinner] = useState(false);
    const [refreshTime, setRefreshTime] = useState(new Date().toLocaleTimeString([], { hour12: true }).replace('pm', 'PM'));
    const { broker, dates,security, exchange, portfolio, entity, strategy } = useSelector(state => state.filter);
    const { columnOptions } = useSelector(state => state.positionTableOption);
    const dispatch = useDispatch()
    const [clientId] = useGlobalState("clientId")
    const [accountIds] = useGlobalState('accountIds')
    const [clientName] = useGlobalState('clientName');
    strategyLists = useSelector(state => state.filter.strategyList)
    const [rowData, setRowData] = useState();

    const dateConversion = (val) => {
        const dateObj = new Date(val);
        const year = dateObj.getFullYear();
        const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); 
        const day = ('0' + dateObj.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    
    }

    const  cellClickedListener = ( ) => {
        setCount(count + 1);
    };

    const defaultColDef = {
        sortable: true,
        editable: false,
        filter: true,
        headerClass:{textAlign:"center"},
        cellStyle: params => {
            if(params?.column?.colId==='unrealizedPnl'){
                if (params.value < 0) {
                    return { color: "red", display: "flex",justifyContent: "flex-end"};
                }else {
                    return {display: "flex",justifyContent: "flex-end", color:"#00cc00"};
                }
            }
            else {
                return {display: "flex",justifyContent: "flex-end"};
            }
        }
    }

    const rowStyle = { background: "" };

    const getRowStyle = params => {
        if (params.value< 0) {
            return { color: "red" };
        }
    }

    const [isFetching,setIsFetching] = useState(true);
    
    useEffect(() => {
        setIsFetching(true);
    
        const body = {
            "startTimestamp": `${dateConversion(dates[0])} 23:15:00`,
            "endTimestamp": `${dateConversion(dates[1])} 23:15:00`,
            positionCompKeyFilter: {
                clientId: clientId,
                tradingSymbols: security === "" ? [] : [security],
                ...(strategy ? { strategyIds: [Number(strategy)] } : { strategyIds: Object.keys(strategyLists).map(item => Number(item))}),
                accountIds: accountIds
            }
        };
    
        const fetchBeta = async () => {
            try {
                const response = await fetch(config.REACT_APP_DEV_URL + 'position/beta', {
                    method: "POST",
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                });
                const betaData = await response.json();
                return betaData;
            } catch (err) {
                console.log("Error in beta:", err);
                return [];
            }
        };
    
        const fetchPositions = async () => {
            try {
                const response = await fetch(config.REACT_APP_DEV_URL + 'position/inMemory', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                });
                const positionsData = await response.json();
                return positionsData;
            } catch (err) {
                console.log("Error in fetching positions:", err);
                return [];
            }
        };
    
        const mergeBetaWithPositions = (positions, betaData) => {
            const betaMap = new Map(
                betaData.map(item => [`${item.trade_date}-${item.trading_symbol}`, item.beta])
            );
        
            return positions.map(position => {
                const key = `${position.date}-${position.tradingSymbol}`;
                return {
                    ...position,
                    beta: betaMap.get(key) || null
                };
            });
        };
        
    
        const fetchData = async () => {
            try {
                const betaData = await fetchBeta();
                const positionsData = await fetchPositions();
    
                const mergedData = mergeBetaWithPositions(positionsData, betaData);
                setRowData(mergedData);
                setRefreshTime(new Date().toLocaleTimeString([], { hour12: true }));
                setTimeout(() => setIsFetching(false), 750);
            } catch (err) {
                console.log("Error in fetchData:", err);
            }
        };
    
        fetchData();
        const intervalId = setInterval(fetchData, 60000);
    
        return () => {
            clearInterval(intervalId);
        };
    }, [security, dates, count, clientName, strategy]);
     
    
    const containerStyle = useMemo(() => ({  height: 'auto', width : '100%' , overflow: 'hidden' }), []);
    const gridStyle = useMemo(() => ({ height: '580px', width : '1680px' }), []);
    const filterStyle = useMemo(() => ({ height: '50px', width : '100%' }), []);
    const tableStyle = useMemo(() => ({ height: '100%', width : '100%', "margin" : "15px", position: "relative", "border" : "2px solid black" }), []);
    const tdStyle = useMemo(() => ({ width : '50%' }), []);
    const halfStyle = useMemo(() => ({width : "500px", "padding-left" : "15px"}), []);

    
    const [open, setOpen] = React.useState(false);

    const optionsUpdater = (stateList)=>{
        const newdata = columnOptions.map((option,i)=>{
            return {...option,sort:stateList[i]}
         })
        
        dispatch(setPositionTableOptionsData(newdata));
    }
    const handleSortingData = (e)=>{
        const state = e.columnApi?.getColumnState();
        const sortState = state?.map(item=>item.sort);
        optionsUpdater(sortState)
    }

    const [isSingleDate,setIsSingleDate] = useState(false);
    useEffect(()=>{
       if(dateConversion(dates[0])===dateConversion(dates[1])){
        setIsSingleDate(true)
       }
       else{
        setIsSingleDate(false)
       }
    },[dates])



    return (
        <>
        <TableRefresher cellClickedListener={cellClickedListener} showSpinner={isFetching}/>
            {
                <div style={gridStyle} className="ag-theme-alpine" >
                { isFetching?
                <ShimmerTable row={7} col={5} />
                :
                <AgGridReact
                    //isSingleDate?[{...columnOptions[0],hide:true},...columnOptions.slice(1)]:
                    columnDefs = {[...columnOptions,
                        {
                            headerName: "", field: 'tradingSymbol',
                            headerClass: "ag-right-aligned-header",
                            width: 180,
                            sort: null,
                            cellRenderer:PositionOrderForm,
                            cellRendererParams: {
                                tableData: rowData
                            }
                        }
                    ]}
                    defaultColDef = {defaultColDef}
                    filterStyle = {filterStyle}
                    //pagination
                    //paginationPageSize={10}
                    getRowStyle = {getRowStyle}
                    rowData = {rowData}
                    onSortChanged={handleSortingData}
                    //domLayout='autoHeight'
                    >
                </AgGridReact>
                }
                </div>
            } 
    </>
  

    )


}

export default PositionsGrid;
export {strategyLists}