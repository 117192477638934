/*eslint-disable*/
import config from '../../config';
import { setNiftyEodPrice, setBankNiftyEodPrice, setNiftyDateOptions, setNiftyStrikeOptions, setBankNiftyDateOptions, setBankNiftyStrikeOptions } from "./OptionChainSlice";

 export const getNiftyEodPrice = (dispatch) => {
  
    fetch(config.CORS_URL+'getLatestIndexEODPrice?zen_id=37761', {
             method : 'GET'
           })
           .then(response => {
             if (!response.ok) {
                 throw new Error("It is error");
               }
               return response.json();
           })
           .then
           (
            data => 
            {
              dispatch(setNiftyEodPrice(data[0].close));
            }
           )
           .catch(error => console.error("Error",error))
 }

 export const getBankNiftyEodPrice = (dispatch) => {
  
  fetch(config.CORS_URL+'getLatestIndexEODPrice?zen_id=37766', {
           method : 'GET'
         })
         .then(response => {
           if (!response.ok) {
               throw new Error("It is error");
             }
             return response.json();
         })
         .then
         (
          data => 
          {
            dispatch(setBankNiftyEodPrice(data[0].close));
          }
         )
         .catch(error => console.error("Error",error))
}

export const getValidBankNiftyZenSecurities = (bankNiftyEodPrice, dispatch) => {
  const todayDate = new Date();
  const startDate = new Date(Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate()));
  const endDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth()+ 2,0));
  const day = String(endDate.getUTCDate()).padStart(2, '0');
  const month = String(endDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = endDate.getUTCFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const startingPrice = Math.round(bankNiftyEodPrice*0.97);
  const endingPrice = Math.round(bankNiftyEodPrice*1.03);
    fetch(config.CORS_URL+'getValidOptionZenSecurities?strike='+startingPrice+","+endingPrice+"&name=BANKNIFTY&expiry="+formattedDate, {
             method : 'GET'
           })
           .then(response => {
             if (!response.ok) {
                 throw new Error("It is error");
               }
               return response.json();
           })
           .then
           (
            data => 
            {
              dispatch(setBankNiftyDateOptions(Array.from( new Set(data.map(item => item.expiry).sort((a,b) => new Date(a) - new Date(b))))));
              dispatch(setBankNiftyStrikeOptions(Array.from( new Set(data.map(item => item.strike).sort()))));
            }
           )
           .catch(error => console.error("Error",error))
 }

 export const getValidNiftyZenSecurities = (niftyEodPrice, dispatch) => {
  const todayDate = new Date()
  const startDate = new Date(Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate()))
  const endDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth()+ 2,0));
  const day = String(endDate.getUTCDate()).padStart(2, '0');
  const month = String(endDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = endDate.getUTCFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const startingPrice = Math.round(niftyEodPrice*0.97);
  const endingPrice = Math.round(niftyEodPrice*1.03);
    fetch(config.CORS_URL+'getValidOptionZenSecurities?strike='+startingPrice+","+endingPrice+"&name=NIFTY&expiry="+formattedDate, {
             method : 'GET'
           })
           .then(response => {
             if (!response.ok) {
                 throw new Error("It is error");
               }
               return response.json();
           })
           .then
           (
            data => 
            {
              dispatch(setNiftyDateOptions(Array.from( new Set(data.map(item => item.expiry).sort((a,b) => new Date(a) - new Date(b))))));
              dispatch(setNiftyStrikeOptions(Array.from( new Set(data.map(item => item.strike).sort()))));
              
            }
           )
           .catch(error => console.error("Error",error))
 }