/*eslint-disable*/
import { RiMoneyRupeeCircleFill } from "react-icons/ri";

const Margin = ({availableMargin, priceGetter}) => {
    return(
        <div className="flex items-center justify-center space-x-2 text-md border rounded-sm px-2 py-2 shadow-sm text-black-800">
        <div className="flex items-center w-24 text-sm font-normal">
            <span>Available cash</span>
        </div>
        <div className="font-semibold flex items-center">
            <RiMoneyRupeeCircleFill className="text-md" size={20} />{priceGetter(availableMargin)}
        </div>
   </div>
    )
}

export default Margin;