/* eslint-disable */

import { element } from "prop-types"

const getUniqueValues = (elements) => {
    const uniqueIds = new Set()
    const uniqueData = elements.filter((data) => {
      if(uniqueIds.has(data.id))
        return false
      else
      {
        uniqueIds.add(data.id)
        return true
      }
    })
    return uniqueData
  }

  const getUniqueSector = (elements) => {
    const uniqueIds = new Set()
    const uniqueData = elements.filter((data) => {
      if(uniqueIds.has(data))
        return false
      else
      {
        uniqueIds.add(data)
        return true
      }
    })
    return uniqueData
  }

  const setGraphElements = (data) => {
    const graphData = []
    const nodes = data.map((element) => element.n)
    const equity = getUniqueValues(nodes.filter((element) => element.label === "Equity"))
    const index = nodes.filter((element) => element.label === "Index")
    // equity.sort((a,b) => a.properties.industry.localeCompare(b.properties.industry))
    equity.sort((a,b) => a.properties.name.localeCompare(b.properties.name))

    const sector = getUniqueSector(equity.map((element) => element.properties.industry))
    for(const i in equity)
    {
      if(equity[i].properties.industry === 'Information Technology')
      {
        graphData.push({data : {id : `${equity[i].properties.industry} Container`, label : 'Container', properties : {name :`${equity[i].properties.industry} Container` }}})
        graphData.push({data : {id : equity[i].properties.industry, label : 'Sector', parent :`${equity[i].properties.industry} Container`, properties : {name :equity[i].properties.industry }}})
      }
      else if(equity[i].properties.industry === 'Bank - Public' ||equity[i].properties.industry === 'Bank - Private')
      {
        graphData.push({data : {id : `Bank Container`, label : 'Container', properties : {name :`Bank Container` }}})
        graphData.push({data : {id : equity[i].properties.industry, label : 'Sector', parent :`Bank Container`, properties : {name :equity[i].properties.industry }}})
      }
      else
        graphData.push({data : {id : equity[i].properties.industry, label : 'Sector', properties : {name :equity[i].properties.industry }}})
      graphData.push({data : {id : equity[i].id, label : equity[i].label, properties : equity[i].properties, parent : equity[i].properties.industry}})
    }
    for(const i in index)
    {
      if(index[i].properties.name === 'NIFTY IT' || index[i].properties.name === 'NIFTY BANK')
       graphData.push({data : {id : index[i].id , parent : `${index[i].properties.sector} Container`,label : index[i].label, properties : index[i].properties}})
      else
        graphData.push({data : index[i]})
    }
  
    for (const i in sector)
    {
      // if(sector[i] === 'Information Technology')
      //   graphData.push({data : {source : index[0].id, target : 'Information Technology Container' }})
      // else if(sector[i] === 'Bank - Public' || sector[i] === 'Bank - Private'){}
      // else
      graphData.push({data : {source : index[0].id, target : sector[i], label : sector[i]}})

    }

    // graphData.push({data : {source : index[0].id, target : 'Bank Container' }})
    graphData.push({data : {source : index.filter(element => element.properties.name === 'NIFTY IT')[0].id, target : 'Information Technology', label : 'Information Technology'}})
    graphData.push({data : {source : index.filter(element => element.properties.name === 'NIFTY BANK')[0].id, target : 'Bank - Private', label : 'Bank - Private'}})
    graphData.push({data : {source : index.filter(element => element.properties.name === 'NIFTY BANK')[0].id, target : 'Bank - Public', label : 'Bank - Public'}})
    return graphData
}

// const setGraphElements = (data) => {
//     const graphData = []
//     const equity = getUniqueValues(data.map((element) => element.n)) 
//     // const constituents = getUniqueValues(data.map((element) => element.r))
//     const index = getUniqueValues(data.map((element) => element.m))
//     const sector = getUniqueSector(data.map((element) => element.n.properties.industry))
//     for(const i in equity)
//     {
//       graphData.push({data : {id : equity[i].properties.industry, properties : {name :equity[i].properties.industry }, label : 'Sector'}})
//       graphData.push({data : {id : equity[i].id, label : equity[i].label, properties : equity[i].properties, parent : equity[i].properties.industry}})
//     }
//     for(const i in index)
//       graphData.push({data : index[i]})
  
//     for (const i in sector)
//       graphData.push({data : {source : index[0].id, target : sector[i], label : sector[i]}})
//     // for(const i in constituents)
//     //     graphData.push({data : {source : constituents[i].start_id, target : constituents[i].end_id,id : constituents[i].id, label : constituents[i].label, properties : constituents[i].properties}})
//     return graphData 
// }

export default setGraphElements;