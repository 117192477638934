/* eslint-disable */

import { useSelector } from "react-redux";
import {Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from './layouts/Admin2.js';
import DerivativeGraphTempalte from "components/DerivativeGraph/container/DerivativeGraphTemplate"

const App = () => {
    const element = useSelector(state => state.graphTemplate.elementCopy)
    return(
        <Routes>
          <Route path="/default/*" element={<AdminLayout />} />
          <Route path="/" element={<Navigate to="/default/dashboard" replace />} />
          <Route path="/default/derivative/:equityName" element = {<DerivativeGraphTempalte elements = {element}/>}/>
        </Routes> 
    )
}

export default App