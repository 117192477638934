/* eslint-disable */
import { setShowChart } from '../../GraphTemplate/container/GraphTemplateSlice' 
import { useDispatch, useSelector } from 'react-redux';
import { setShowDerivative, setShowForm } from '../../GraphTemplate/container/GraphTemplateSlice';
import { setShowPopover, setSiblingEquity} from './MainTemplateSlice';
import { setTargetNode } from '../../GraphTemplate/container/GraphTemplateSlice';
import Popover from './Popover';
import { setDerivativeIsIndex, setDerivativePopover, setShowChart as setDerivativeShowChart } from '../../DerivativeGraph/container/DerivativeGraphSlice';

const PopoverTemplate = ({cyRef, elements}) => {
    const dispatch = useDispatch()
    const cy = cyRef.current
    const popoverPosition = useSelector(state => state.mainTemplate.popoverPosition)
    const popoverNode = useSelector(state => state.graphTemplate.popoverNode)
    const isIndex = useSelector(state => state.mainTemplate.isIndex)
    const isSector = useSelector(state => state.mainTemplate.isSector)
    // const isDerivative = useSelector(state => state.derivativeGraphTemplate.isDerivative)
    const isDerivative = false
    const listStyle = {
        position : 'absolute',
        left : popoverPosition.x ,
        top : popoverPosition.y,
        width : '12%'
    }
    const listItemStyle = {
        textDecoration : 'none', 
        color : 'black',
        display : 'flex',
        alignItems : 'center'
    }

    const imageStyle = {
        height : '25px',
        width : '20px',
        marginRight : '3%',
    }
    const showDerivative = (e) => {
       e.stopPropagation()
        localStorage.setItem('popoverNode', JSON.stringify(popoverNode.json()))
        dispatch(setDerivativePopover(popoverNode))
        dispatch(setDerivativeIsIndex(isIndex))
    //    dispatch(setSiblingEquity(elements.filter((element) => element.data.parent === popoverNode.data().properties.industry)))
       dispatch(setShowPopover(false));
       e.stopPropagation()
    }

    const showChart = (e) => {
       e.stopPropagation()
        dispatch(setTargetNode(popoverNode.data().properties.name))
        dispatch(setShowPopover(false))   
        dispatch(setShowChart(true))
        e.stopPropagation()
        // cyRef.current.center()
        // cyRef.current.panBy({x: 500, y: 0})
    }
    const showForm = (e) => {
       e.stopPropagation()
        dispatch(setShowPopover(false));
        dispatch(setShowForm(true));
        e.stopPropagation()

    }
    const hideForm = (e) => {
       e.stopPropagation()
        dispatch(setShowPopover(false));
        dispatch(setShowForm(false));
        e.stopPropagation()
    }

    return (
        <Popover
            showForm = {showForm}
            showChart = {showChart}
            showDerivative = {showDerivative}
            hideForm = {hideForm}
            listItemStyle ={listItemStyle}
            listStyle = {listStyle}
            isIndex = {isIndex}
            isSector = {isSector}
            isDerivative = {isDerivative}
            popoverNode = {popoverNode}
            imageStyle = {imageStyle}
        />
    )
}

export default PopoverTemplate;