/* eslint-disable */
import React, {useMemo} from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { useState, useEffect } from "react";
import "./TradesGrid.css";
import { useGlobalState } from '../../globalVariable';
import { Button, Spinner } from "reactstrap";
import { ShimmerTable } from 'react-shimmer-effects';
import { useSelector, useDispatch } from 'react-redux';
import { setTradestableOptionsData } from '../container/TradesTableOptionSlice';
import { use } from 'cytoscape';
import TableRefresher from 'components/TableRefreshComponet/TableRefresher';
import config from "../../../config"

let strategyLists
const TradesGrid = ({other}) => {
    
    const [count, setCount]             = useState(1);
    const [showSpinner, setShowSpinner] = useState(false);
    const [refreshTime, setRefreshTime] = useState(new Date().toLocaleTimeString([], { hour12: true }).replace('pm', 'PM'));
    const { dates,broker, security, exchange, portfolio, entity, strategy } = useSelector(state => state.filter);
    const {columnOptions} = useSelector(state => state.tradesTableOption);
    const dispatch = useDispatch()
    const [clientId]                    = useGlobalState("clientId")
    const [accountIds]                  = useGlobalState("accountIds")
    const [clientName]                  = useGlobalState('clientName');
    strategyLists = useSelector(state => state.filter.strategyList)
    const dateConversion = (val) => {
        const dateObj = new Date(val);
        const year = dateObj.getFullYear();
        const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); 
        const day = ('0' + dateObj.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }

    const [rowData, setRowData] = useState();
    
    const  cellClickedListener = ( ) => {
        setCount(count + 1);
    };
         
    const defaultColDef = useMemo( ()=>({
        sortable: true,
        editable: false,
        filter: true,
        cellStyle: { textAlign: "center" }
    }),[])
    const rowStyle = { background: "" };

    const getRowStyle = params => {
        if (params.value< 0) {
            return { color: "#87CEEB" };
        }
    }

    const [isFetching,setIsFetching] = useState(true);
    useEffect(() => {
        setIsFetching(true)
        const body = {
            startTimestamp: `${dateConversion(dates[0])} 00:00:00`,
            endTimestamp: `${dateConversion(dates[1])} 23:59:59`,
            positionCompKeyFilter: {
                clientId: clientId,
                tradingSymbols: security === "" ? [] : [security],
                // Conditionally include strategyIds only if strategy is not empty
                ...(strategy ? { strategyIds: [Number(strategy)] } : { strategyIds : Object?.keys(strategyLists)?.map(item => Number(item))}),
                accountIds: accountIds
            }
        };
        fetch(config.REACT_APP_DEV_URL+'trade/getTrades',
        {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
        .then(result => result.json())
        .then(json => {
            setRefreshTime(new Date().toLocaleTimeString([], {hour12 : true }));
            setRowData(Array.from(json));
            setTimeout(()=>setIsFetching(false),750);
        })
        .catch((err) => {
            console.log(err.message) 
        });
      }, [security,dates,count,clientId,clientName, strategy]);
    
    const containerStyle = useMemo(() => ({  height: 'auto', width : '100%' , overflow: 'hidden' }), []);
    const gridStyle = useMemo(() => ({ height: '580px', width : '1260px' }), []);
    const filterStyle = useMemo(() => ({ height: '50px', width : '100%' }), []);
    const tableStyle = useMemo(() => ({ height: '100%', width : '100%', "margin" : "15px", position: "relative", "border" : "2px solid black" }), []);
    const tdStyle = useMemo(() => ({ width : '50%' }), []);
    const halfStyle = useMemo(() => ({width : "500px", "padding-left" : "15px"}), []);

    
    const [open, setOpen] = React.useState(false);
    const optionsUpdater = (stateList)=>{
        const newdata = columnOptions.map((option,i)=>{
            return {...option,sort:stateList[i]}
         })
        
        dispatch(setTradestableOptionsData(newdata));
    }
    const handleSortingData = (e)=>{
        const state = e.columnApi?.getColumnState();
        const sortState = state?.map(item=>item.sort);
        optionsUpdater(sortState)
    }

    return (
        <>
        <TableRefresher cellClickedListener={cellClickedListener} showSpinner={isFetching}/>
            <div style={gridStyle} className="ag-theme-alpine">
            {
            isFetching?
            <ShimmerTable row={5} col={5} />
            :
                <AgGridReact
                    columnDefs={columnOptions}
                    defaultColDef={defaultColDef}
                    rowData = {rowData}
                    filterStyle={filterStyle}
                    onSortChanged={handleSortingData}
                    //domLayout='autoHeight'
                >
                </AgGridReact>
            }
            </div>
    </>
    )
}

export default TradesGrid;
export {strategyLists}
