/* eslint-disable */
import config from '../../../config';

  const getData = (dispatch, targetNode,setChartData) => {
  const todayDate = new Date()
  const formattedCurrentMonth = parseInt(todayDate.getMonth()) > 10 ? parseInt(todayDate.getMonth() + 1)  : "0"+ parseInt(todayDate.getMonth() + 1)
  const formattedCurrentDate =  todayDate.getDate() > 10 ? todayDate.getDate() : "0"+todayDate.getDate()
  const currentDate = formattedCurrentDate+"-"+ formattedCurrentMonth +"-" + todayDate.getFullYear()
 
  const pastMonthDate = new Date()
  pastMonthDate.setMonth(pastMonthDate.getMonth() - 2)
  const formattedPastMonth = parseInt(pastMonthDate.getMonth()) >= 10 ? parseInt(pastMonthDate.getMonth()+1) : "0"+parseInt(pastMonthDate.getMonth()+ 1) 
  const formattedPastDate =  pastMonthDate.getDate() > 10 ? pastMonthDate.getDate() : "0"+pastMonthDate.getDate()
  const pastDate = formattedPastDate+"-"+formattedPastMonth+"-"+pastMonthDate.getFullYear()
   fetch(config.CORS_URL+'getEquityEODPriceWithSymbol?equity='+targetNode+'&trade_date='+pastDate+','+currentDate, {
            method : 'GET'
          })
          .then(response => {
            if (!response.ok) {
                throw new Error("It is error");
              }
              return response.json();
          })
          .then(data => { dispatch(setChartData(data));})
          .catch(error => console.error("Error",error))
}

export const getIndexData = async (dispatch, setChartIndexData, popoverIndexLabels) => {
  const todayDate = new Date()
  const formattedCurrentMonth = parseInt(todayDate.getMonth()) > 10 ? parseInt(todayDate.getMonth() + 1)  : "0"+ parseInt(todayDate.getMonth() + 1)
  const formattedCurrentDate =  todayDate.getDate() > 10 ? todayDate.getDate() : "0"+todayDate.getDate()
  const currentDate = formattedCurrentDate+"-"+ formattedCurrentMonth +"-" + todayDate.getFullYear()
 
  const pastMonthDate = new Date()
  pastMonthDate.setMonth(pastMonthDate.getMonth() - 2)
  const formattedPastMonth = parseInt(pastMonthDate.getMonth()) >= 10 ? parseInt(pastMonthDate.getMonth()+1) : "0"+parseInt(pastMonthDate.getMonth()+ 1) 
  const formattedPastDate =  pastMonthDate.getDate() > 10 ? pastMonthDate.getDate() : "0"+pastMonthDate.getDate()
  const pastDate = formattedPastDate+"-"+formattedPastMonth+"-"+pastMonthDate.getFullYear()
  
  const indexDataPromises = popoverIndexLabels.map(indexName => {
    
    return fetch(`${config.CORS_URL}getIndexEODPrice?trade_date=${pastDate},${currentDate}&index_name=${encodeURIComponent(indexName)}`, {
      method: 'GET'
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("It is error");
        }
        return response.json();
      })
      .then(data => ({ indexName, data }))
      .catch(error => {
        console.error("Error", error);
        return null;
      });
  });

  Promise.all(indexDataPromises)
    .then(results => {
      const filteredResults = results.filter(result => result !== null);
      const updatedData = filteredResults.map(result => ({ indexName: result.indexName, data : result.data }));
      dispatch(setChartIndexData(updatedData));
    })
    .catch(error => console.error("Error processing index data", error));
}

export default getData