/* eslint-disable */

import PnlGridPresentation from "../presentation/PnlGrid";
import { saveDates } from "../../../reducers/FilterSlice";

import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  dates: state.filter.dates,
  other: state.filter.abc
});

const mapDispatchToProps = { saveDates };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PnlGridPresentation);