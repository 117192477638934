/* eslint-disable */
const getEODPrice = (targetNode, popoverIndexLabels, chartData, chartIndexData, setData, isIndex, setYAxisData) => {
  if (chartData && chartIndexData) {
    const data = [];
    const yAxisData = []
    // Filter and format data for each popover index label
    if(!isIndex)
    {
      for (const index in popoverIndexLabels) {
        const filteredData = chartIndexData[index].data
        const formattedData = filteredData.map(data => [
          Date.UTC(
            parseInt(data.trade_date.split("-")[0]),  // Year
            parseInt(data.trade_date.split("-")[1]) - 1,  // Month (zero-based)
            parseInt(data.trade_date.split("-")[2])  // Day
          ),
          data.close
        ]);
        formattedData.sort((a,b) => a[0] - b[0])
        data.push({type : 'line',name: chartIndexData[index].indexName, data: formattedData, yAxis : (+index) + 1 });
        yAxisData.push({title: {
          text: chartIndexData[index].indexName,
          labels: {
            format: '{value}' // Format for NIFTY 50
          }
        },
        opposite: true})
      }
  
      // Filter and format data for targetNode
      const formattedTargetData = chartData.map(data => [
        Date.UTC(
          parseInt(data.trade_date.split("-")[0]),  // Year
          parseInt(data.trade_date.split("-")[1]) - 1,  // Month 
          parseInt(data.trade_date.split("-")[2])  // Day
        ),
        data.close
      ]);
  
      data.unshift({ type : 'line',name: targetNode, data: formattedTargetData, yAxis : 0 });
      yAxisData.unshift({title: {
        text: targetNode,
        labels: {
          format: '{value}' // Format for NIFTY 50
        }
      },
      opposite: false})
    }
    else
    {
      const filteredTargetData = chartIndexData[0].data;
      const formattedTargetData = filteredTargetData.map(data => [
        Date.UTC(
          parseInt(data.trade_date.split("-")[0]),  // Year
          parseInt(data.trade_date.split("-")[1]) - 1,  // Month (zero-based)
          parseInt(data.trade_date.split("-")[2])  // Day
        ),
        data.close
      ]);
      formattedTargetData.sort((a,b) => a[0] - b[0])
      
      data.unshift({ type : 'line',name: chartIndexData[0].indexName, data: formattedTargetData, yAxis : 0 });
      yAxisData.push({title: {
        text: chartIndexData[0].indexName,
        labels: {
          format: '{value}' // Format for NIFTY 50
        }
      },
      opposite: false})
    }
    
    setData([...data]);
    setYAxisData([...yAxisData])

  } else {
    console.log("Please wait for the data to load");
  }
};

export default getEODPrice;
