/* eslint-disable */
import React from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/phoenix-dashboard.scss?v=1.3.0";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Provider, useSelector } from 'react-redux';
import store from './app/store';
import styles from "./index.css";
import App from "App";
import 'qtip2/dist/jquery.qtip.min.css';

ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);