/*eslint-disable*/
import React, {useMemo, useRef, forwardRef, useEffect} from 'react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { useState } from "react";
import "./TradesFilter.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {saveDates,saveSecurity,saveExchange,saveStrategy,saveBroker,saveEntity,savePortfolio} from "reducers/FilterSlice";
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import {
Button,
Input,
Form,
Label,
FormGroup,
Col
} from 'reactstrap';
import { useZenSecurities1 } from 'components/getSecurities';
import { useRealtimeZenSecurities } from 'components/getSecurities';
import './date-style.css';


const TradesFilter2 = () => {
      const filter = useSelector(state => state.filter);
      const filterDictionary = {
        dates:filter.dates,
        broker: filter.broker,
        security: filter.security,
        exchange: filter.exchange,
        portfolio: filter.portfolio,
        entity: filter.entity,
        strategy: filter.strategy
    };
  
    const [dateRange, setDateRange] = useState([filterDictionary.dates[0],filterDictionary.dates[1]]);
    const [startDate, endDate] = dateRange;

    const inputRef = useRef(null);

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input className="form-control" onClick={onClick} ref={ref}
        value={value}>
      </input>
    ));

    const filterOptions = (options, { inputValue }) => {
      return options.filter(option =>
        option.title.startsWith(inputValue.toUpperCase())
      ).slice(0,3000)
    }

    const dispatch = useDispatch();
    const [securities,setSecurities] = useState([]);
    const [autocompleteValue,setAutoCompleteValue] = useState("");
    const [dummy, setDummy] = useState('dummy')
    //const zenSecuritiesData1 = useZenSecurities1(dummy);
    const zenSecuritiesData1 = useRealtimeZenSecurities();




    
    /**
     * The below fetchData method will check for
     * the zenSecurities data is local storage and
     * will check the last updated date and current date is equal
     * If not api call will be made and data will be updated in local storage
     */
    /*
    async function fetchData() {
      try {
          let today = new Date().toLocaleDateString();
          if(!localStorage.getItem('zenSecurities') || today!==localStorage.getItem('lastUpdated')){
            const response = await fetch(process.env.REACT_APP_REQ_URL+'getValidZenSecurities', {
              method: 'GET'
            });
            const data = await response.json();
            localStorage.setItem('zenSecurities',JSON.stringify(data));
            localStorage.setItem('lastUpdated',new Date().toLocaleDateString());
            console.log('Network call made to getZenSecurities')
          }
          else{
            console.log('No Network call made to getZenSecurities')
            //console.log('From local storage ',JSON.parse(localStorage.getItem('zenSecurities')))  
          }
          
          const instruments = JSON.parse(localStorage.getItem('zenSecurities'));
          
          let list = [];
          console.log('Instrument types for ',filterDictionary.exchange)
          for (const key in instruments){
            instruments[key]?.forEach((item,v)=>{
              if(item.exchange === filterDictionary.exchange){
                list.push({title:item.tradingSymbol,type:key,lotSize:item.lotSize,key:item.tradingSymbol+""+v+""+item.lotSize});
                console.log(key);
              }
            })
          }
        //console.log('filtered list based on Exchange - ',filterDictionary.exchange)
        console.log(list);
        setSecurities(list);
      } catch (error) {
        console.error('Error fetching getZenSecurities data:', error);
      }
    };
    */
    /*
    Need to change for new api getSecurity
      for (const key in data){
              data[key]?.forEach((item,v)=>{
                if(item.exchange === filterDictionary.exchange){
                  list.push({title:item.tradingSymbol,type:key,lotSize:item.lotSize,exchange:item.exchange,index:v});
                }
              })
            }
    */
    
  /*
  data["FUT"]?.forEach((item,v)=>{
              if(item.exchange === filterDictionary.exchange){
                list.push({title:item.tradingSymbol,type:"FUT",lotSize:item.lotSize,exchange:item.exchange,index:v});
              }
            })
  */
  
  function sortOptions(options) {
              return options.sort((a, b) => {
                // Extract the option type (PE or CE) from the title
                  const aType = a.title.slice(-2);
                  const bType = b.title.slice(-2);
                
                // Extract the trading symbol without the option type
                  const aSymbol = a.title.slice(0, -2);
                  const bSymbol = b.title.slice(0, -2);
                
                // If the symbols are different, sort by symbol
                  if (aSymbol < bSymbol) return -1;
                  if (aSymbol > bSymbol) return 1;
                
                // If the symbols are the same, prioritize CE over PE
                  if (aType === 'CE' && bType === 'PE') return -1;
                  if (aType === 'PE' && bType === 'CE') return 1;
                
                // If both are same (both PE or both CE), maintain original order
                  return 0;
              });
            }
             
  function filterData(){
          let list = [];
          zenSecuritiesData1.then((data)=>{
            for (const exchange in data){
              if(exchange === filterDictionary.exchange){
               data[exchange]["EQ"]?.map((item,v)=>{
                list.push({
                  title:item.tradingSymbol,
                  type:"EQ",
                  lotSize:item.lotSize,
                  exchange:exchange,
                  index:v});
               })
               let list1 = list.sort((a,b)=>a.title-b.title)
               list = []

               data[exchange]["CE"]?.map((item,v)=>{
                list.push({
                  title:item.tradingSymbol,
                  type:"OPT",
                  lotSize:item.lotSize,
                  exchange:exchange,
                  index:v
                });
               })

               data[exchange]["PE"]?.map((item,v)=>{
                list.push({
                  title:item.tradingSymbol,
                  type:"OPT",
                  lotSize:item.lotSize,
                  exchange:exchange,
                  index:v
                });
               })
               let list2 = sortOptions(list);
               list = []


               data[exchange]["FUT"]?.map((item,v)=>{
                list.push({
                  title:item.tradingSymbol,
                  type:"FUT",
                  lotSize:item.lotSize,
                  exchange:exchange,
                  index:v});
               })
               let list3 = list.sort((a,b)=>a.title-b.title)
               
               list = [...list1,...list2,...list3]
              }
            }
            
          }).then(()=>{
            setSecurities(list);
          })
          
    }

    
    useEffect(()=>{
      filterData();
    },[filterDictionary.exchange])

    //adding event listener for selecting text in Securities Autocomplete bar
    //while clicking SHIFT + HOME - select to highlight
    // DEL to delete text
    /*
    const highlightText = () => {
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.focus();
        inputElement.setSelectionRange(0, autocompleteValue.length);
      }
    };
    
  
    useEffect(()=>{

      const handleKeyDown = (event) => {
        if (event.key === 'Home' && event.shiftKey) {
          //event.preventDefault();
          highlightText();
        } else if (event.key === 'Delete') {
          if (document.getSelection().toString() === autocompleteValue) {
            setAutoCompleteValue('')
          }
        }
      };
  
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.addEventListener('keydown', handleKeyDown);
      }
  
      return () => {
        if (inputElement) {
          inputElement.removeEventListener('keydown', handleKeyDown);
        }
      };
    },[inputRef])
    */
    

   
    return (
          <div className='flex space-x-6 font-semibold'>
                
                  <Label >Date
                  <div className='form-control' style={{ height: '43px',cursor:"pointer" }}>
                <DatePicker 
                    selectsRange={true}     
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange([update[0],update[1]]);
                        if (update[1]!==null) dispatch(saveDates([update[0],update[1]]));
                      } 
                    }
                    isClearable={false}
                    className="nomarg w-64"
                    placeholderText='Choose Date Range'
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    portalId={"date-picker"}
                    //for external date z index
                  >
                    <Button color="primary" onClick={()=>{
                      const todayDate = new Date()
                      setDateRange([todayDate,todayDate]);
                      dispatch(saveDates([todayDate,todayDate]));  
                    }}>
                      Today
                    </Button>

                    <Button color="primary" onClick={() => {
                      const today = new Date();
                      if (today.getDay() === 1) {
                          today.setDate(today.getDate() - 3);
                      } else {
                          today.setDate(today.getDate() - 1);
                      }
                      setDateRange([today, today]);
                      dispatch(saveDates([today, today]));  
                  }}>
                  Yesterday
                  </Button>
                  </DatePicker>

                  </div>
                  </Label>
                

                
                  <Label>Security
                  <Autocomplete
                        disablePortal
                        noOptionsText={securities.length?"No results found":"Loading..."}
                        inputValue={autocompleteValue}
                        onInputChange={(event, newInputValue) => {
                          setAutoCompleteValue(newInputValue);
                        }}
                        onChange={(e,value)=>{
                          dispatch(saveSecurity(value?value.title:""))
                          //setAutoCompleteValue(value?value.title:"")
                        }}
                        autoHighlight={true}
                        id="combo-box-demo"
                        options={securities}
                        groupBy={(option) => option.type}
                        getOptionLabel={(option) => option.title}
                        filterOptions={filterOptions}
                        sx={{ width:'280px','& .MuiAutocomplete-inputRoot': { height: 42 },"& .MuiInputBase-root":{padding:"0px 0px 0px 5px"}}}
                        renderInput={(params) =>{
                          //console.log(params);
                          return  <TextField inputRef={inputRef} placeholder="Securities..." {...params} />
                        }}
                        renderOption={(props, item) =>{
                          return(
                            <span {...props} key={item.title+item.exchange+item.index}>
                              {item.title}
                            </span>
                          )
                        } 
                      }
                      />
                  </Label>
                
                
                
                  <Label>Exchange
                  <Input type="select"
                        className=' w-44' 
                      value={filterDictionary.exchange}
                      onChange={e=>{
                        dispatch(saveExchange((e.target.value)));
                      }}>
                          <option  value="ZEN_EXCHANGE">All</option>
                          <option >ZEN_EXCHANGE</option>
                          <option >NSE</option>
                          <option >NFO</option>
                      </Input>
                  </Label>
                

                
                  <Label>Strategy
                  <Input type="select"
                  value={filterDictionary.strategy}
                  disabled={false}
                  onChange={e=>{
                    dispatch(saveStrategy((e.target.value)));
                  }}>
                  <option value={""}>ALL</option>
                  <option value={"1"}>One</option>
                  <option value={"11000"}>2PM_STRATEGY</option>
                  <option value={"11001"}>2PM_STRATEGY_TEST1</option>
                  <option value={"11002"}>2PM_STRATEGY_TEST2</option>
                  </Input>
                  </Label>
                
                  {/* <Label>Portfolio
                  <Input type="select"
                     value={filterDictionary.portfolio}
                     disabled={true}
                    onChange={e=>{
                      console.log(e.target.value)
                      dispatch(savePortfolio(e.target.value))
                    }}
                    ><option>ALL</option>
                    <option>portfolio</option>
                  </Input>
                  </Label>
                
                  <Label>Entity
                  <Input type="select"
                 value={filterDictionary.entity}
                 disabled={true}
                  onChange={e=>{
                  console.log(e.target.value)
                  dispatch(saveEntity(e.target.value))
                  }}>
                        <option>ALL</option>
                        <option>entity</option>
                  </Input>
                  </Label> */}
                
                  <Label>Broker Account 
                  <Input type="select" 
                     value={filterDictionary.broker}
                     disabled={true}
                    onChange={e=>{
                      dispatch(saveBroker(e.target.value))
                    }}>
                        <option>ALL</option>
                        <option>ZEN_BROKER</option>
                  </Input>
                  </Label>
                
          </div>
    )


};

export default TradesFilter2;
