/*eslint-disable*/
import React from "react";
import completed from "./tick-in-circle.png"
import no from "./cross-in-circle.png"
import loading from "./loading.jpg";
import statergy_timer from "./statergy_timer3.png"
import { Tooltip } from "@mui/material";

const TooltipComponent = ({value,img})=>{
  return(
    <Tooltip title={`${value}`} placement="bottom"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -12],
                    },
                  },
                ],
              },
            }}
            >
            <img
              alt={`${value}`}
              src={img}
              className="h-4"
            />
    </Tooltip>
  )
}


const OrderStatusCellRender = (props)=> {
  const value = props.value
  const statergyId = props?.data?.positionCompKey?.strategyId
    if(value==='OPEN'){
      return(
        <span className="text-sm font-semibold text-blue-600">{value}</span>
      )
    }
    else if(value==="COMPLETED"){
      return(
        <TooltipComponent value={value} img={completed}/>
      )
    }
    else if(value==="CANCELLED"){
      return(
        <TooltipComponent value={value} img={no}/>
      )
    }
    else if(statergyId===1 && value==="PENDING"){
      return (
        <TooltipComponent value={value} img={loading}/>
      )
    }
    else if(statergyId!==1 && value==="PENDING"){
      return (
        <TooltipComponent value={value} img={statergy_timer}/>
      )
    }
  
  return (
    <span className="text-sm font-semibold text-red-600">{value}</span>
  )
}

export default OrderStatusCellRender;