/*eslint-disable */
import React from "react";
import { useState, useEffect } from 'react';
import GraphTemplate from "components/GraphTemplate/container/GraphTemplate";
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
// import {
//   dashboard24HoursPerformanceChart,
//   dashboardEmailStatisticsChart,
//   dashboardNASDAQChart,
// } from "variables/charts.js";

import axios from "axios";
import OrderTable from "../components/OrderTable/OrderTable";



function Dashboard() {
  return (
    <Col md="12">
      <GraphTemplate/>
    </Col>
  );
}

export default Dashboard;
