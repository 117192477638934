/* eslint-disable */
import React, { useState , useEffect} from 'react';
import MainTemplate from '../../CytoscapeGraph/container/MainTemplate'
import DropDown from '../../Main/DropDown'
import ChartTemplate from '../../Chart/container/ChartTemplate'
import '../presentation/GraphTemplate.css'
import { setWindowSize, setShowDerivative, setShowChart, setShowForm, setGraphResult, setElementCopy, setBackgroundColor } from './GraphTemplateSlice';
import { useDispatch, useSelector } from 'react-redux'
import OrderForm from '../../OrderForm/Field/OrderForm';
import { setChartData, setChartIndexData, setIsIndex, setPopoverPosition , setShowPopover} from '../../CytoscapeGraph/container/MainTemplateSlice';
import { IoMdClose } from "react-icons/io";

let element;
const GraphTemplate = () => {
 const [elements , setElements]= useState([])
  
  const dispatch = useDispatch()
  const showChart = useSelector(state => state.graphTemplate.showChart)
  const targetNode = useSelector(state => state.graphTemplate.targetNode)
  const showForm = useSelector(state => state.graphTemplate.showForm);
  const popoverNode = useSelector(state => state.graphTemplate.popoverNode)
  const popoverPosition = useSelector(state => state.mainTemplate.popoverPosition)
  const backgroundColor = useSelector(state => state.graphTemplate.backgroundColor)

  useEffect(() => {
    dispatch(setElementCopy(structuredClone(elements)))
    element = elements
  }, [elements])
  const hideForm = () => {
    dispatch(setShowForm(false))
  }
  const hideChart = () => {
    dispatch(setShowChart(false))
  }
  document.addEventListener('keydown', function(event){
    if(event.key === 'Escape')
    {      
      dispatch(setShowPopover(false))
      dispatch(setShowForm(false))
    }
  })
  useEffect(() => {
    const updateWindowSize = () => {
      dispatch(setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      }));
      
      popoverNode.forEach((popoverNodeItem) => 
        dispatch(setPopoverPosition({ x : popoverNodeItem.renderedPosition().x + 60, y : popoverNodeItem.renderedPosition().y - 40}))
        
      )
    
    };
    window.addEventListener('resize', updateWindowSize);
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, [popoverNode, popoverPosition]);

  useEffect(() => {
    dispatch(setShowPopover(false))
    dispatch(setShowForm(false))
}, []);

  return(
    <div> 
        <div>
          <DropDown
                  elements = {elements}
          />
          <div id ='graphTemplate' >
            <div id = "securityGraph">
              <MainTemplate
                elements = {elements}
                setElements = {setElements}
                setGraphResult = {setGraphResult}
              />
            </div>
            {
              showChart &&
              <div id = "comparisionChart">
                <div className="btn-close" onClick={hideChart}>
                  <IoMdClose 
                    style={
                      {
                        position : 'absolute', 
                        right : '3%', 
                        top: '6%', 
                        zIndex : 1,
                        backgroundColor: backgroundColor
                      }
                    }

                    onMouseEnter={() => dispatch(setBackgroundColor('white'))}
                    onMouseLeave={() => dispatch(setBackgroundColor('transparent'))}
                    onClick={() => {
                      dispatch(setChartData([]))
                      dispatch(setChartIndexData([]))
                    }}
                  />
                </div>
                <ChartTemplate />
              </div>
            }

          </div>
      </div> 
      
      {
        showForm &&
        <div id = 'form'>
          <OrderForm
            targetNode = {popoverNode.data().properties.name}
            trade= 'BUY'
            setFormOpen={setShowForm}
            hideForm={hideForm}
          />
        </div>
      }
      
    </div>
    
      
)
}
export default GraphTemplate; 
export {element}

