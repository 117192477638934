/* eslint-disable */

import React from 'react'
import { saveTradingSymbol,saveFormOpen,saveType, saveIsConfirmed, saveOpen } from '../container/PositionActionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Infinity } from 'lucide-react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useEffect } from 'react';
import { saveOrderDetails, saveOrderData } from '../container/PositionActionSlice';

//buttons Exit or Add for cellrenderer
//here we have only buttons to the cell render
//And auto square off feature.
export default function PositionOrderForm(props) {

    const dispatch = useDispatch();
    const { value, tableData } = props;
    const rowData = tableData?.filter((row)=>row.tradingSymbol===value)[0];
    
    const orderDetails = {
        symbol : value,
        type: rowData.position<0?"BUY":"SELL",
        quantity: rowData.position>0?rowData.position:-1*rowData.position,
        orderType: "MARKET"
    }
    

    const handleAdd = ()=>{   
        dispatch(saveTradingSymbol(value))
        dispatch(saveFormOpen(true));
        dispatch(saveType("BUY"));

    }

    const handleExit = () =>{    
        dispatch(saveTradingSymbol(value))
        dispatch(saveFormOpen(true));
        dispatch(saveType("SELL"))
    }

    //for auto square off
    
    const handleAutoSquareOff = () =>{
        const positionData = rowData
        const data = {
            "clientId": positionData?.positionCompositeKey?.clientId,
            "accountId": positionData?.positionCompositeKey?.accountId,
            "strategyId": positionData?.positionCompositeKey?.strategyId,
            "broker": positionData?.positionCompositeKey?.broker,
            "exchange": "ZEN_EXCHANGE",
            "transactionType": positionData.position<0?"BUY":"SELL",
            "quantity": positionData.position>0?positionData.position:-1*positionData.position,
            "product": "CNC",
            "validity": "DAY",
            "minutes": null,
            "stopLossLimitPrice": null,
            "triggerPrice": null,
            "limitPrice": null,
            "tradingSymbol": positionData.tradingSymbol,
            "orderType": "MARKET",
            "methodType": "POST"
        } 
        dispatch(saveOrderData(data))
    }

    return (
        <div className='flex justify-center content-center space-x-2'>
        <span
            onClick={handleAdd}
            aria-label='BUY' 
            className="cursor-pointer inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">BUY</span>
        <span
            onClick={handleExit}
            aria-label="SELL" 
            className="cursor-pointer inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">SELL</span>
        <span
            onClick={()=>{
                dispatch(saveOrderDetails(orderDetails));
                dispatch(saveOpen(true))
                handleAutoSquareOff();
            }}
            aria-label="Auto Square Off" 
            className="cursor-pointer inline-flex items-center rounded-md bg-blue-50 px-1 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/10"><Infinity/></span>
        </div>
    )
}
