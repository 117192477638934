/* eslint-disable */
import TradesFilterPresentation from "../presentation/TradesFilter";

import React, { useRef, useState } from "react";

import saveDates from "../../../reducers/FilterSlice";

import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
 
});

const mapDispatchToProps = { saveDates };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TradesFilterPresentation);