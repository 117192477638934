
    const config = {
  "DB_HOST": "192.168.20.13",
  "DB_PORT": "5432",
  "DB_USER": "postgres",
  "DB_PASSWORD": "root",
  "DB_NAME": "equity_graph",
  "DB_CONNECT": "http://192.168.20.12:3003/",
  "CORS_URL": "http://192.168.20.12:9000/",
  "REACT_APP_DEV_URL": "http://192.168.20.12:9190/",
  "GRAPH_NAME": "'equity_graph'"
};
    export default config;
    