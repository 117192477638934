/* eslint-disable */
import CytoscapeComponent from 'react-cytoscapejs';
import { useSelector } from 'react-redux';
import styleSheet from '../presentation/DerivativeGraphStylesheet';
import coseBilkentLayout from '../presentation/DerivativeGraphLayout';
import klay from 'cytoscape-klay'
import { setBackgroundColor, setShowForm} from '../../GraphTemplate/container/GraphTemplateSlice';
import { setShowPopover} from '../../CytoscapeGraph/container/MainTemplateSlice';
import {layout } from '../presentation/DerivativeGraphLayout';
import cytoscape from 'cytoscape';
import { setFetchCount, setShowChart,  setDefaultZoom, setChartData, setChartIndexData, updateNodePosition } from './DerivativeGraphSlice';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import DerivativeChartTemplate from '../../DerivativeChart/container/DerivativeChartTemplate';
import '../presentation/DerivativeGraph.css';
import { IoMdClose } from "react-icons/io";
import PopoverTemplate from './PopoverTemplate';
import OrderForm from '../../OrderForm/Field/OrderForm'
cytoscape.use(klay)
const DerivativeGraph = ({filteredElements,cyRef, showPopover, dispatch, fetchCount, options,
  handleRightClick, hideForm, hideChart, handleZoom, backgroundColor, handleLayoutStop, firstLayoutStop}) => {
  const windowSize = useSelector(state => state.graphTemplate.windowSize)
  const showChart = useSelector(state => state.derivativeGraphTemplate.showChart);
  const showForm = useSelector(state => state.graphTemplate.showForm)
  const pathname = useLocation();
  const chartTitle = useSelector(state => state.derivativeGraphTemplate.chartTitle)
  const defaultZoom = useSelector(state => state.derivativeGraphTemplate.defaultZoom)
  let cyRefCopy = {}
  useEffect(() => {
    dispatch(setShowPopover(false))
    dispatch(setFetchCount(0))
    dispatch(setShowChart(false))
    dispatch(setShowForm(false))
}, []);

useEffect(() => {

   
  // if(cyRef.current)
  if (cyRef.current && fetchCount >= 3  ) 
  {
    cyRef.current.layout(layout).run();
    // cyRef.current.layout(layout).run();
    // cyRef.current.layout(layout).run();

    dispatch(setDefaultZoom(cyRef.current.zoom()))
  }
  cyRefCopy = cyRef.current
}, [cyRef, filteredElements, fetchCount])
  
  
    return( 
      <div id='derivativeContainer'>
      {
        fetchCount < 3 && 'Loading ...'
      }
      {fetchCount >= 3  && 
        <div id='derivativeGraph'>
       {<button type='button' 
          style={
              {
                backgroundColor : 'rgb(191, 246, 195)', 
                border : '1px solid white', borderRadius: '5%', 
                margin :'1%', padding : '0.5%'
              }
            } 
            onClick={() => {
              if(cyRef.current) 
              {
                cyRef.current.zoom(defaultZoom); 
                cyRef.current.center()
              }
            }}>
              Reset Graph
            </button>}

          <CytoscapeComponent
            elements={filteredElements}
            randomize={false}
            style={{ width: windowSize.width, height: windowSize.height }}
            options = {options}
            cy={(cy) => { 
              cyRef.current = cy;
              cy.maxZoom(3)
              cy.minZoom(0.2)
              cy.on('zoom', () =>handleZoom(cy))
              cy.nodes().on('cxttap', (e) => handleRightClick(e.target, cy))  
              cy.on('click', (e)=> {
                if(e.target === cy)
                  dispatch(setShowPopover(false))
              })  
              // cy.on('layoutstop', () => handleLayoutStop(cy))
              // cy.on('dragfree', 'node', (event) => dispatch(updateNodePosition([event.target.id(),event.target.position()])))
            }}
            stylesheet={styleSheet} 
          />
        </div>
        }
        {
          showPopover && 
          <div id='derivativePopover'>
            <PopoverTemplate
              cyRef={cyRef}
            />
          </div>          
        }
        {
          showChart &&
          <div id='derivativeChart'>
            <div className="btn-close" onClick={hideChart}>
            <IoMdClose 
              style={
                {
                  position : 'absolute', 
                  right : '1%',
                  top : '5%', 
                  zIndex : 1,
                  backgroundColor : backgroundColor
                }}
                onMouseEnter={() => dispatch(setBackgroundColor('white'))}
                onMouseLeave={() => dispatch(setBackgroundColor('transparent'))}
                onClick={() => {
                  dispatch(setChartData([]))
                  dispatch(setChartIndexData([]))
                }}
              />
            </div>
            <DerivativeChartTemplate/>
          </div>  
        }
        {
          showForm &&
          <div id = 'form'>
            <OrderForm
              targetNode = {chartTitle}
              trade= 'BUY'
              hideForm={hideForm}
              setFormOpen={setShowForm}
            />
          </div>
        }
      </div>
    )
}

export default DerivativeGraph;