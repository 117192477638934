/* eslint-disable */
const brightenContainer = (sector, cy) => {
  if(sector === 'Bank - Public' || sector === 'Bank - Private')
  cy.nodes(`[properties.name = 'Bank Container']`).forEach(element => {
    element.style({
      opacity : 1
    })        
  });

  else if(sector === 'Information Technology')
  cy.nodes(`[properties.name = 'Information Technology Container']`).forEach(element => {
    element.style({
      opacity : 1
    })        
  });
}
const applyFilter = (cyRef, sector, equity) => {
  const cy = cyRef.current

  if(sector !== null && equity !== null)
  {
    cy.nodes().style({
      opacity : 0.2
    })
    cy.edges().style({
      'line-opacity' : 0.2
    })
   
    if(cy.nodes(`[properties.fullname = '${equity}']`).data().properties.industry === sector)
    {
      cy.nodes(`[properties.name = '${sector}']`).style({
        opacity : 1
      })
      cy.nodes(`[properties.fullname = '${equity}']`).style({
        opacity : 1
      })
    }
    brightenContainer(sector, cy)
    
  }
  else if(sector!== null || equity !== null)
  {
    cy.nodes().style({
      opacity : 0.2
    })
    cy.edges().style({
      'line-opacity' : 0.2
    })
    if(sector!== null)
    {
      cy.nodes(`[properties.name = '${sector}']`).forEach(element => {
        element.style({
          opacity : 1
        })        
      });
      cy.nodes(`[properties.industry = '${sector}']`).forEach(element => {
        element.style({
          opacity : 1
        })
      })
      brightenContainer(sector, cy)
    
    }
    else if(equity!== null)
    {
      cy.nodes(`[properties.fullname = '${equity}']`).forEach(element => {
        element.style({
          opacity : 1
        })
      });
      const sector = cy.nodes(`[properties.fullname = '${equity}']`).parent()
      sector.forEach(element => {
        element.style({
          opacity : 1
        })
      });
      brightenContainer(sector?.data().properties.name, cy)      
    
    }
    
    
  }
else
{
cy.nodes().style({
  opacity : 1 
})
cy.edges().style({
  'line-opacity' : 1,
  // color : 'rgba(255,255,255,0)'
})
}

}


export default applyFilter;