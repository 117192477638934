/*eslint-disable*/

import { ArrowDown, ArrowUp} from 'lucide-react';
import React, { useState, useEffect } from 'react';

const CustomHeader = (props) => {
    const [pnlType, setPnlType] = useState(props.type+"LTDPnl");
    const sortArray = ["","asc","desc"];
    const [sortState,setSortState] = useState(0);

    useEffect(() => {
        let colDef = props.api.getColumnDefs();
        let newDef = colDef.map((item)=>{
            if(item.headerName === props?.headerName){
                return {...item,field:pnlType}
            }
            return item
        })
        props.api.setColumnDefs(newDef)
        props.api.refreshCells({ force: true, columns: [props.column.colId] });
    }, [pnlType]);

    const handlePnlTypeChange = (event) => {
        const newPnlType = event.target.value;
        setPnlType(newPnlType);
    };

  return (
    <div className="px-4 py-2 flex space-x-4">
      <div onClick={()=>{
        setSortState(sortState===0?1:sortState===1?2:0);
        props.setSort(sortArray[sortState===0?1:sortState===1?2:0])
    }}>{props.displayName}</div>
      <div className='h-1'>
      {sortState===0?null:sortState===1?<ArrowUp size={16}/>:<ArrowDown size={16}/>}
      </div>
      <select className='text-sm text-black/70 font-semibold rounded-sm'
      value={pnlType} onChange={handlePnlTypeChange}>
        <option value={`${props.type}DTDPnl`}>DTD</option>
        <option value={`${props.type}WTDPnl`}>WTD</option>
        <option value={`${props.type}MTDPnl`}>MTD</option>
        <option value={`${props.type}YTDPnl`}>YTD</option>
        <option value={`${props.type}LTDPnl`}>LTD</option>
      </select>
    </div>
  );
};

export default CustomHeader;
