/* eslint-disable */
import PositionsGridPresentation from "../presentation/PositionsGrid";

import React, { useRef, useState } from "react";

import saveDates from "../../../reducers/FilterSlice";

import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  dates: state.filter.dates,
  other: state.filter.abc
});

const mapDispatchToProps = { saveDates };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PositionsGridPresentation);