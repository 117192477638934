import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { useState} from 'react';
import './OptionChain.css';
import { useSelector } from 'react-redux';

const OptionChain = ({gridApiRef, setGridApi,symbol, month, date , monthNames, sortOptions, setSortOptions, dispatch}) => 
{
  const columnOptions = useSelector(state => state.optionChainTemplate.columnOptions)
  const defaultColDef = 
  {
    // sortable : true,
    supressMovable : true
  };

  const [columnDefs] = useState(columnOptions);
      
  const getRowId = (params) => params.data.key;

  const onGridReady = (params) => 
  {

    gridApiRef.current = params.api; 
    setGridApi(params.api);
    
    // gridApiRef.current.applyColumnState({ state: sortState });
  
    params.api.setFilterModel(
      {
        underlying_zen_id : 
        {
          filterType: 'number',
          type: 'equals',
          filter: symbol 
        }           
      });
    params.api.onFilterChanged();
  };

  const onFirstDataRendered = (params) => {
    const todayDate = new Date();
    let startDate, endDate;
    

    setTimeout(() => {
      const filterModel =
      {
        underlying_zen_id: 
        {
              filterType: 'number',
              type: 'equals',
              filter: symbol 
        }           
      };
      if(date)
      {
        filterModel['expiry_date'] = 
        {
          filterType : 'date',
          type : 'equals',
          dateFrom : date,   
          filterParams : 
          {
            inRangeInclusive: true,
          }  
        };
      }
      else if (month)
      {
        if(todayDate.getMonth() === monthNames.indexOf(month))
        {
            startDate = new Date(todayDate).toISOString().split('T')[0];
            endDate = new Date(todayDate.getFullYear(),todayDate.getMonth() +1 , 1).toISOString().split('T')[0];
        }
        else
        {
            startDate = new Date(new Date(todayDate).getFullYear(),new Date(todayDate).getMonth()+1 , 2).toISOString().split('T')[0];
            endDate = new Date(new Date(todayDate).getFullYear(),new Date(todayDate).getMonth() +2 , 1).toISOString().split('T')[0];
        }
        filterModel['expiry_date'] = 
        {
          filterType: 'date',
          type: 'inRange',
          dateFrom: startDate, 
          dateTo:endDate,  
          filterParams: 
          {
            inRangeInclusive: true,
          }  
        };
      }     
      
      params?.api?.setFilterModel(filterModel);
      params?.api?.onFilterChanged();
    }, 0);
  };  

  return (
    <div style={{paddingLeft : '0px'}}>
      <div id='optionChainTableContainer'>              
        <div className="ag-theme-alpine"  style={{ height: 850, width: 1300 }}>
          <AgGridReact
            ref={gridApiRef}
            // rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            getRowId={getRowId}
            domLayout='normal'
            rowBuffer={10}
            suppressDragLeaveHidesColumns={true}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            gridOptions={{suppressSorting : true}}
            // onSortChanged={handleSortingData}  
          />
        </div>
      </div>
    </div>   
  );
}

export default OptionChain;
