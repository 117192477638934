/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  availableMargin : 0
};

const MarginTemplateSlice = createSlice({
  name: 'marginTemplate',
  initialState,
  reducers: {
    setAvailableMargin(state, action){
        state.availableMargin = action.payload
    }
  }
});

export const { setAvailableMargin } = MarginTemplateSlice.actions;
export default MarginTemplateSlice.reducer;
