/* eslint-disable */
import Highcharts, { chart } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import '../presentation/ChartTemplate.css'
import accessibility from 'highcharts/modules/accessibility';
accessibility(Highcharts)

const ChartPage = (
    {
      targetNode, 
      options, 
      data
    }
  ) => {
  if(Object.keys(data).length!=0)
    return (
        <HighchartsReact 
          highcharts = {Highcharts} 
          constructorType={'stockChart'}
          options = {options}
        />
    )
  else
    return <p>Loading</p>
}

export default ChartPage;