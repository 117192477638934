import React from "react";
import Form from "react-bootstrap/Form";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

export default function ValidityTemplate({
      validityType,
      showOption,
      setShowOption,
      handleValidation,
      validityValue,
      Minutes,
      register,
      formData,
      setFormData,
}) {
  return (
    <div className="container">
      <div
        className="flex items-center justify-center p-1 space-x-4 bg-blue-300 w-64 mt-4"
        onClick={() => {
          setShowOption(!showOption);
        }}
      >
        <p>{showOption ? "Hide options" : "More options"}</p>
        {showOption ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
      </div>

      {showOption && (
        <div className="flex space-x-12">
          <div>
            {validityType.map((el, i) => (
              <Form.Check
              key={i}
              value={el}
              label={el}
              {...register("validity")}
              type="radio"
              onClick={() => {
                  handleValidation(el);
                  setFormData({ ...formData, validity: validityValue });
              }}
              id={`inline-radio-${i}`} 
              className="text-md text-black" 
            />

            ))}
          </div>

          <div>
            {
              validityValue==="MINUTE"?
              <Form.Group className="mb-1 mt-4 flex-col">
                <p className="text-sm">
                  Minutes
                </p>
              <Form.Select
                className="border-blue-300 border-2 p-1 rounded-md"
                autoComplete="true"
                defaultValue={1}
                {...register("minutes")}
                disabled={
                  validityValue === "DAY" || validityValue === "IOC"
                }
              >
                
                { validityValue === "DAY" ||
                  validityValue === "IOC" ||
                  validityValue === null  ||
                  Minutes.map((el) => (
                    <option key={el} value={el}>
                      {el} minutes
                    </option>
                  ))}
              </Form.Select>
              </Form.Group>
              :null
            }

          </div>
        </div>
      )}
    </div>
  );
}
