import React, { useState } from "react";
import ValidityTemplate from "./ValidityTemplate";
import "./Validity.css";

const validityType = ["DAY", "MINUTE", "IOC"];
const Minutes      = [1, 2, 3, 5, 10, 15, 30, 45, 60, 90, 120];

export default function Validity({ formData, setFormData, register }) {
  const [showOption, setShowOption]       = useState(false);
  const [validityValue, setValidityValue] = useState(null);

  return (
    <div>
      <ValidityTemplate
        showOption       = {showOption}
        setShowOption    = {setShowOption}
        validityType     = {validityType}
        validityValue    = {validityValue}
        handleValidation = {setValidityValue}
        register         = {register}
        Minutes          = {Minutes}
        formData         = {formData}
        setFormData      = {setFormData}
      />
    </div>
  );
}
