/* eslint-disable */

const styleSheet = [
    {
        selector : 'edge',
        style : {
          'curve-style': 'bezier',
            'target-arrow-shape' : 'triangle',
            // 'target-arrow-color' : '#f8bc9c',
            'target-arrow-color' : 'black',
            'line-color' : 'black',
            // 'text-background-color': '#FFF',
            // 'text-background-opacity': 1,
            // 'text-background-padding': '3px',
            'width' : 1.5,
            'arrow-scale' : 1,
            // color(ele) { return ele ? (ele.data('fontColor') ? ele.data('fontColor') : '##0498f5') : '##0498f5'; },
            'font-size': '10px',
            'text-rotation': 'autorotate'
        }
    },

    {
        selector : 'node',
        style : {
            'label' : `data(properties.name)`,
            "text-valign" : "center",
            "text-halign" : "center",
            "width" : 160,
            "height" : 105,
            "font-size" : '25px',
            'background-color': function (ele) { return ele ? (ele.data('backgroundColor') ? ele.data('backgroundColor'): '#9bd2a0') : '#9bd2a0'; },
            'border-width': '3px',
            'border-color': function (ele) { return ele ? (ele.data('borderColor')? ele.data('borderColor') :'#7fb884') : '#7fb884'; },
            'border-opacity': 0.6,
            // color(ele) { return ele ? (ele.data('fontColor')? ele.data('fontColor'): '#000'): '#000'; },
            'text-wrap': 'wrap',
            'text-max-width': function (ele) { return ele ? (ele.data('size') ? ele.data('size'): 55) : 55; },
        }
    },

    {
      selector : `node[label ='Index']`,
      style : {
          width : 190,
          height : 150,
          backgroundColor : 'pink',
          'border-color': function (ele) { return ele ? (ele.data('borderColor')? ele.data('borderColor') :'#ce8ba4') : '#ce8ba4'; },

      }
    },
    {
        selector: 'node:selected',
        style: {
        'border-width': '6px',
         'border-opacity' : 1
        },
    },
  
    {
        selector : 'node[label ="Sector"]',
        style : {
            "background-color" : 'rgba(255, 255, 255, 0)',
            'border-color' : 'rgba(255, 255, 255, 0)',
            "padding" : 15,
            "font-size" : 0,
            height : 55
        }
    },
    {
        selector : 'node[label ="Container"]',
        style : {
            "background-color" : 'rgba(255, 255, 255, 0)',
            'border-color' : 'rgba(255, 255, 255, 0)',
            "font-size" : 0,
            height : 55
        }
    },
        
  ]

export default styleSheet;