/*eslint-disable*/
import React,{useState} from "react";

import PnlGrid from "../components/PnlGrid/container/PnlGrid";
import TradesFilter from "../components/TradesFilter/container/TradesFilter";
import store from "../app/store";

// reactstrap components
import {
  UncontrolledAlert,
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import PnlLineChartContainer from "components/PnlLineChart/PnlLineChartContainer";

function Pnl() {

 return (
  <>
      <Col xs="auto">
        <Card>      
            <PnlGrid />
        </Card>
      </Col>
      {/*<Col xs="auto">
        <Card>
          <CardBody>
            <PnlLineChartContainer/>
          </CardBody>
        </Card>
        </Col>*/
      }
  </>

 );
}

export default Pnl;
