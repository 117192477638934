/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  portfolioBeta : "Loading"
};

const BetaSlice = createSlice({
  name: 'portfolioBeta',
  initialState,
  reducers: {
    setBeta(state, action){
        state.portfolioBeta = action.payload
    }
  }
});

export const { setBeta } = BetaSlice.actions;
export default BetaSlice.reducer;
