/*eslint-disable*/
import { useGlobalState } from "components/globalVariable"
import Margin from "./Margin"
import { useEffect } from "react"
import getAvailableMargin from "./cypherResult"
import { setAvailableMargin } from "./MarginTemplateSlice"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
const MarginTemplate = () => {
    const [clientId] = useGlobalState("clientId")
    const availableMargin = useSelector(state => state.marginTemplate.availableMargin)
    const dispatch = useDispatch()
    const priceGetter = (params) => { 
        return Number(params).toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0
        });
    }

    useEffect(() => {
        if(clientId)
        getAvailableMargin(clientId, dispatch, setAvailableMargin)
    },[clientId, availableMargin])

    return( 
    <Margin
        availableMargin = {availableMargin}
        priceGetter = {priceGetter}
    />)
}

export default MarginTemplate;